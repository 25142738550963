import React from 'react';
import './Home2.css'; // Add corresponding CSS styles for responsive and accessible design
import { useState } from 'react';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <div className="logo-container">
        <img src="2.png" alt="Logo" className="logo-image" />
      </div>
      <header className={`header sticky ${menuOpen ? 'active' : ''}`} role="banner">
        <div className="container">
          <button
            className="menu-toggle"
            aria-label="Toggle navigation menu"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            ☰
          </button>
          <nav aria-label="Main Navigation">
            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
              {['Home', 'Services', 'Subscription Plans', 'Community', 'Blog', 'Testimonials', 'Contact Us'].map((item) => (
                <li key={item}>
                  <a href={`#${item.toLowerCase().replace(/\s+/g, '')}`}>{item}</a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="cta-buttons">
            <button className="cta-primary" aria-label="Join Vittapath Now">Join Now</button>
            <button className="cta-secondary" aria-label="Explore Free Resources">Explore Free Resources</button>
          </div>
        </div>
      </header>
    </>
  );
};


const HeroSection = () => (
  <section className="hero" id="home" role="region" aria-labelledby="hero-title">
    <div className="hero-content">
      <h1 id="hero-title">Your Path Toward Consistency & Disciplined Trading</h1>
      <p>Take the first step with our tailored strategies and expert mentorship.</p>
      <div className="hero-buttons">
        <button className="cta-primary" aria-label="Start Trading Journey">Start Your Trading Journey Today</button>
        <button className="cta-secondary" aria-label="Explore Services">Explore Services</button>
      </div>
    </div>
    <div className="hero-image">
      <img src="1.jpg" alt="Trading tools and success" />
    </div>
  </section>
);

const FeatureCard = ({ icon, title, description }) => (
  <div className="feature-card" role="region">
    <i className="icon">{icon}</i>
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

const OverviewSection = () => (
  <section className="overview" id="services" role="region" aria-labelledby="overview-title">
    <h2 id="overview-title">Why Choose Vittapath Trading Services?</h2>
    <div className="features">
      {[
        { icon: '📈', title: 'Daily Market Analysis', description: 'Stay ahead with our in-depth daily market insights.' },
        { icon: '📊', title: 'Live Trading Sessions', description: 'Experience real-time trading with our expert traders.' },
        { icon: '🧠', title: 'Psychology Workshops', description: 'Master the mindset needed for consistent success.' },
      ].map((feature, index) => (
        <FeatureCard key={index} {...feature} />
      ))}
    </div>
  </section>
);

const Testimonial = ({ text, author }) => (
  <div className="testimonial">
    <p>"{text}"</p>
    <span>- {author}</span>
  </div>
);

const TestimonialsSection = () => (
  <section className="testimonials" id="testimonials" role="region" aria-labelledby="testimonials-title">
    <h2 id="testimonials-title">What Our Clients Say</h2>
    <div className="testimonial-slider">
      {[
        { text: 'Maintaining a trading journal is strongly emphasized and well-structured templates are provided. This habit has been transformative in understanding personal trading patterns and areas for improvement', author: 'Rahul K' },
        { text: 'The daily sessions are invaluable. The focus on specific currencies ensures depth over breadth, allowing for detailed exploration of trends and potential trades. The combination of technical and fundamental analysis provides a holistic view of the market, although it might feel overwhelming for those new to the tools used.', author: ' Krithik' },
        { text: 'Strategy discussions are insightful and make learning easier. Backtesting with real data is helpful, but having structured templates could improve it further', author: ' Mustafa S' },
        { text: 'The mindset sessions are life-changing. They help in managing emotions and staying focused, which is crucial for long-term success.', author: 'Shrinidhi' },
        { text: 'Live trading sessions are practical and engaging. Watching experts explain their trades helps bridge the gap between theory and practice. ', author: 'Shilpa P' },

      ].map((testimonial, index) => (
        <Testimonial key={index} {...testimonial} />
      ))}
    </div>
  </section>
);

const Footer = () => (
  <footer className="footer" role="contentinfo">
    <div className="footer-container">
      <div className="footer-column">
        <h3>Quick Links</h3>
        <ul>
          {['Home', 'Services', 'Contact Us'].map((link) => (
            <li key={link}>
              <a href={`#${link.toLowerCase().replace(/\s+/g, '')}`}>{link}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className="footer-column">
        <h3>Contact Info</h3>
        <p>Email: <a href="mailto:info@vittapath.com">info@vittapath.com</a></p>
        <p>Phone: <a href="tel:+1234567890">+1234567890</a></p>
      </div>
      <div className="footer-column">
        <h3>Follow Us</h3>
        <div className="social-icons">
  {[
    { platform: 'Facebook', link: 'https://www.facebook.com/profile.php?id=61565094553088' },
    { platform: 'Twitter', link: 'https://x.com/VittapathTrad' },
    { platform: 'Instagram', link: 'https://www.instagram.com/vittapath.trading/' },
    { platform: 'Youtube', link: 'https://www.youtube.com/channel/UCVr8409hpxAigBpECb05EbA' },
  ].map(({ platform, link }) => (
    <a key={platform} href={link} aria-label={`Visit our ${platform} page`} target="_blank" rel="noopener noreferrer">
      <i className={`fab fa-${platform.toLowerCase()}`}></i>
    </a>
  ))}
</div>

      </div>
      <div className="footer-column">
        <h3>Address</h3>
        <p>Pune<br /> Maharashtra, India</p>
      </div>
    </div>< br/>
    <div className="footer-bottom">
      <p>&copy; {new Date().getFullYear()} Vittapath. All Rights Reserved.</p>
    </div>
  </footer>
);


const Home2 = () => (
  <div>

    <Header/>
    <main>
      <HeroSection />
      <OverviewSection />
      <TestimonialsSection />
    </main>
    <Footer />
  </div>
);

export default Home2;
