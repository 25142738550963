
import './App.css';
import HomePage from './pages/HomePage';
import Home from './pages/Home';
import Home1 from './pages/Home1';
import Home2 from './pages/Home2';
function App() {
  return (
    <div className="App">
      <Home2 />
    </div>
  );
}

export default App;
